import { post } from "jquery";
import axiosClient from "./axiosClient";

const referenceApi = {
    // Get reference list
    getReferenceList: (params) => {
        const url = '/reference';
        return axiosClient.get(url, { params });
    },

    // Get reference info by id
    getReferenceInfo: (referenceId) => {
        const url = `/reference/${referenceId}`;
        return axiosClient.get(url);
    },

    // Update reference info
    updateReferenceInfo: (referenceId, params) => {
        const url = `/reference/${referenceId}`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.put(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Create new reference
    createReference: (params) => {
        const url = `/reference/create`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Delete references
    deleteReferences: (referenceIds) => {
        const url = `/reference/delete`;
        return axiosClient.post(url, {
            referenceIds: referenceIds
        });
    },

    // Get attributes suggest
    getAttributesSuggest: (attribute) => {
        const url = '/reference/suggest';
        return axiosClient.post(url, {
            attribute: attribute
        });
    },
};

export default referenceApi;