import miscApi from "api/miscApi";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// get dashboard data
export const thunkGetDashboardData = createAsyncThunk('app/getDashboardData', async (params, thunkAPI) => {
    const res = await miscApi.getDashboardData({});
    return res.data;
});

// get config
export const thunkGetConfig = createAsyncThunk('app/getConfig', async (params, thunkAPI) => {
    const res = await miscApi.getConfig({});
    return res.data;
});

const appSlice = createSlice({
    name: 'app',
    initialState: {
        activePath: '',

        isGettingDashboardData: false,
        dashboardData: {},

        configs: {},
    },
    reducers: {
        setActivePath: (state, action) => {
            const path = action.payload;
            state.activePath = path;
        },
    },
    extraReducers: {
        // get dashboard data
        [thunkGetDashboardData.pending]: (state, action) => {
            state.isGettingDashboardData = true;
        },
        [thunkGetDashboardData.rejected]: (state, action) => {
            state.isGettingDashboardData = false;
        },
        [thunkGetDashboardData.fulfilled]: (state, action) => {
            state.isGettingDashboardData = false;
            const { result, dashboard } = action.payload;
            if (result === 'success' && dashboard) {
                state.dashboardData = dashboard;
            }
        },

        // get config
        [thunkGetConfig.fulfilled]: (state, action) => {
            const { result, configs } = action.payload;
            if (result === 'success' && configs) {
                state.configs = configs;
            }
        },
    },
});

const { reducer, actions } = appSlice;
export const { setActivePath } = actions;
export default reducer;