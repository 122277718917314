import icLogo from 'assets/icons/ic_logo_moneygain.png';
import icGoogle from 'assets/icons/ic_google.svg';
import icLogoHeader from 'assets/icons/logo.svg';
import icMenuHandle from 'assets/icons/ic_menu_handle.svg';
import icFlagEn from 'assets/images/flags/ic_flag_en.svg';
import icFlagUs from 'assets/images/flags/ic_flag_us.svg';
import icFlagVi from 'assets/images/flags/ic_flag_vi.svg';
import icLogoDNK from 'assets/icons/ic_logo_dnk.jpg';

// Icons
export const AppIcons = {
    icLogo,
    icGoogle,
    icLogoHeader,
    icMenuHandle,
    icFlagEn,
    icFlagUs,
    icFlagVi,
    icLogoDNK,
};

// Images
export const AppImages = {
    img404: require('assets/images/404.jpg'),
    imgBlankAvatar: require('assets/images/img_blank_avatar.png'),
    imgSignInAsideBg: require('assets/images/img_signin_aside_bg.png'),
};