import UserHelper from 'general/helpers/UserHelper';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function AdminRoute(props) {
    const isAuth = UserHelper.checkAccessTokenValid();
    const isAdmin = UserHelper.checkAdmin();

    return isAuth ? (isAdmin ? <Route {...props} /> : <Redirect to='/dashboard' />) : <Redirect to='/sign-in' />
}

export default AdminRoute;