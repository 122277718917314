import authApi from 'api/authApi';
import { updateAxiosAccessToken } from 'api/axiosClient';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import Global from 'general/Global';
import WebsocketHelper from 'general/helpers/WebsocketHelper';

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Sign in
export const thunkSignIn = createAsyncThunk('auth/signIn', async (params, thunkAPI) => {
    const res = await authApi.signIn(params);
    return res.data;
});

// Get current user info
export const thunkGetCurrentUserInfo = createAsyncThunk('auth/getCurrentUserInfo', async (params, thunkAPI) => {
    const res = await authApi.getCurrentUserInfo(params);
    return res.data;
});

// Sign out
export const thunkSignOut = createAsyncThunk('auth/signOut', async function (params, thunkAPI) {
    const res = await authApi.signOut(params);
    localStorage.removeItem(PreferenceKeys.accessToken);
    localStorage.removeItem(PreferenceKeys.accessTokenExpired);
    return res.data;
});

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isSigningIn: false,
        current: {},
        isOnlineStatus: false,
        isAdmin: false,
    },
    reducers: {
        signOut: (state, action) => {
            state.error = '';
            state.current = {};
            state.isAdmin = false;
            Global.gIsAdmin = false;
        },

        setOnlineStatus: (state, action) => {
            state.isOnlineStatus = action.payload;
        }
    },
    extraReducers: {
        // Sign in
        [thunkSignIn.pending]: (state, action) => {
            state.isSigningIn = true;
        },
        [thunkSignIn.rejected]: (state, action) => {
            state.isSigningIn = false;
            state.error = action.error;
        },
        [thunkSignIn.fulfilled]: (state, action) => {
            state.isSigningIn = false;
            const data = action.payload;
            const { result, account } = data;
            // alert(account.accessToken);
            if (result === 'success' && account) {
                state.current = account;
                state.isAdmin = account.level === 0;
                Global.gIsAdmin = account.level === 0;

                if (account.accessToken) {
                    localStorage.setItem(PreferenceKeys.accessToken, account.accessToken);
                    updateAxiosAccessToken(account.accessToken);
                }
                if (account.expirationDate) {
                    localStorage.setItem(PreferenceKeys.accessTokenExpired, account.expirationDate);
                }

                const { username, password } = account;
                if (username && password) {
                    WebsocketHelper.login(username, password);
                }
            }
        },

        // Get current user info
        [thunkGetCurrentUserInfo.fulfilled]: (state, action) => {
            const data = action.payload;
            const { result, account } = data;
            if (result === 'success' && account) {
                state.current = account;
                state.isAdmin = account.level === 0;
                Global.gIsAdmin = account.level === 0;

                if (account.accessToken) {
                    localStorage.setItem(PreferenceKeys.accessToken, account.accessToken);
                }
                if (account.expirationDate) {
                    localStorage.setItem(PreferenceKeys.accessTokenExpired, account.expirationDate);
                }

                const { username, password, accessToken } = account;
                // console.log({ account });
                if (username) {
                    WebsocketHelper.loginByToken(username, localStorage.getItem(PreferenceKeys.accessToken));
                }
            }
        },

        // Sign out
        [thunkSignOut.fulfilled]: (state, action) => {
            const data = action.payload;
            // console.log({ data });
            if (data) {
                state.current = {};
                state.isAdmin = false;
                Global.gIsAdmin = false;
            }
        },
    }
});

const { reducer, actions } = authSlice;
export const { signOut, setOnlineStatus } = actions;
export default reducer;