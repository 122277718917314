import axiosClient from "./axiosClient";

const listingApi = {
    // Get listing list
    getListingList: (params) => {
        const url = '/listing';
        return axiosClient.get(url, { params });
    },

    // Get listing info by id
    getListingInfo: (listingId) => {
        const url = `/listing/${listingId}`;
        return axiosClient.get(url);
    },

    // Update listing info
    updateListingInfo: (listingId, params) => {
        const url = `/listing/${listingId}`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.put(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Create new listing
    createListing: (params) => {
        const url = `/listing/create`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            if ((key === 'images' || key === 'videos') && Array.isArray(value)) {
                value.forEach(item => {
                    formData.append(key, item);
                })
            } else {
                formData.append(key, value);
            }
        }
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Delete listings
    deleteListings: (listingIds) => {
        const url = `/listing/delete`;
        return axiosClient.post(url, {
            listingIds: listingIds
        });
    },

    // Get my listing
    getMyListing: (params) => {
        const url = '/listing/my';
        return axiosClient.get(url, { params });
    },

    // Delete files
    deleteFiles: (listingId, filePathIds) => {
        const url = '/listing/deleteFiles';
        return axiosClient.post(url, {
            listingId: listingId,
            files: filePathIds
        });
    },

    // Add files
    addImageFiles: (listingId, imageFiles) => {
        const url = '/listing/addFiles';
        const formData = new FormData();
        formData.append("listingId", listingId);
        imageFiles.forEach(image => {
            formData.append("images", image);
        })
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Add video files
    addVideoFiles: (listingId, videoFiles) => {
        const url = '/listing/addFiles';
        const formData = new FormData();
        formData.append("listingId", listingId);
        videoFiles.forEach(video => {
            formData.append("videos", video);
        })
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

};

export default listingApi;