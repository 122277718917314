import videoApi from "api/videoApi";
import Global from "general/Global";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get video list
export const thunkGetVideoList = createAsyncThunk('video/list', async (params, thunkAPI) => {
    const res = await videoApi.getVideoList(params);
    return res.data;
});

// Update video info
export const thunkUpdateVideoInfo = createAsyncThunk('video/update', async (params, thunkAPI) => {
    const res = await videoApi.updateVideoInfo(params.videoId, params.values);
    return res.data;
});

// Create video
export const thunkCreateVideo = createAsyncThunk('video/create', async (params, thunkAPI) => {
    const res = await videoApi.createVideo(params);
    return res.data;
});

// Delete video
export const thunkDeleteVideos = createAsyncThunk('video/delete', async (videoIds, thunkAPI) => {
    const res = await videoApi.deleteVideos(videoIds);
    return res.data;
});

const videoSlice = createSlice({
    name: 'video',
    initialState: {
        isGettingVideoList: false,
        videos: [],
        pagination: { perPage: Global.gDefaultPagination },

        isUpdatingVideo: false,
        isCreatingVideo: false,
        isDeletingVideo: false,
    },
    reducers: {
        setPaginationPerPage: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                }
            }
        },
    },
    extraReducers: {
        // Get video list
        [thunkGetVideoList.pending]: (state, action) => {
            state.isGettingVideoList = true;
        },
        [thunkGetVideoList.rejected]: (state, action) => {
            state.isGettingVideoList = false;
        },
        [thunkGetVideoList.fulfilled]: (state, action) => {
            state.isGettingVideoList = false;
            const { result, videos, total, count, page } = action.payload;
            if (result === 'success') {
                if (videos) {
                    state.videos = videos;
                }
                state.pagination = {
                    ...state.pagination,
                    total: total,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },

        // Update video info
        [thunkUpdateVideoInfo.pending]: (state, action) => {
            state.isUpdatingVideo = true;
        },
        [thunkUpdateVideoInfo.rejected]: (state, action) => {
            state.isUpdatingVideo = false;
        },
        [thunkUpdateVideoInfo.fulfilled]: (state, action) => {
            state.isUpdatingVideo = false;
        },

        // Create video
        [thunkCreateVideo.pending]: (state, action) => {
            state.isCreatingVideo = true;
        },
        [thunkCreateVideo.rejected]: (state, action) => {
            state.isCreatingVideo = false;
        },
        [thunkCreateVideo.fulfilled]: (state, action) => {
            state.isCreatingVideo = false;
        },

        // Delete video
        [thunkDeleteVideos.pending]: (state, action) => {
            state.isDeletingVideo = true;
        },
        [thunkDeleteVideos.rejected]: (state, action) => {
            state.isDeletingVideo = false;
        },
        [thunkDeleteVideos.fulfilled]: (state, action) => {
            state.isDeletingVideo = false;
        },
    },
});

const { reducer, actions } = videoSlice;
export const { setPaginationPerPage } = actions;
export default reducer;