import axiosClient from "./axiosClient";

const videoApi = {
    // Get video list
    getVideoList: (params) => {
        const url = '/video';
        return axiosClient.get(url, { params });
    },

    // Get video info by id
    getVideoInfo: (videoId) => {
        const url = `/video/${videoId}`;
        return axiosClient.get(url);
    },

    // Update video info
    updateVideoInfo: (videoId, params) => {
        const url = `/video/${videoId}`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.put(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Create new video
    createVideo: (params) => {
        const url = `/video/create`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Delete videos
    deleteVideos: (videoIds) => {
        const url = `/video/delete`;
        return axiosClient.post(url, {
            videoIds: videoIds
        });
    }
};

export default videoApi;