import axiosClient from "./axiosClient";

const accountApi = {
    // Get user list
    getUserList: (params) => {
        const url = '/account';
        return axiosClient.get(url, { params });
    },

    // Get user info by id
    getUserInfo: (userId) => {
        const url = `/account/${userId}`;
        return axiosClient.get(url);
    },

    // Update user info
    updateUserInfo: (userId, params) => {
        const url = `/account/${userId}`;
        return axiosClient.put(url, params);
    },

    // Create new user
    createUser: (params) => {
        const url = '/account/create';
        return axiosClient.post(url, params);
    },

    // Delete users
    deleteUsers: (userIds) => {
        const url = `/account/delete`;
        return axiosClient.post(url, {
            accountIds: userIds
        });
    }
};

export default accountApi;