import { setOnlineStatus } from 'app/authSlice';
import { addNewMessageSessionId, updateSessionMessage } from 'features/ManageMessage/messageSlice';
import AppConfigs from 'general/constants/AppConfigs';
import PreferenceKeys from 'general/constants/PreferenceKeys';
// import store from 'app/store';
let store;

var W3CWebSocket = require('websocket').w3cwebsocket;

const sTag = '[WebsocketHelper]'

class WebsocketHelper {
    // MARK: --- Params ---
    wsClient = null;

    // MARK: --- Functions ---
    // constructor
    constructor() {
        this.initWebsocket();
    }

    initWebsocket() {
        this.wsClient = new W3CWebSocket(AppConfigs.wsUrl, '');

        this.wsClient.onerror = () => {
            console.log(`${sTag} connection error`);
            store.dispatch(setOnlineStatus(false));
            this.autoReconnect();
        };

        this.wsClient.onopen = () => {
            console.log(`${sTag} websocket client connected`);

            // auto re login
            const current = store.getState().auth.current;
            if (current) {
                this.loginByToken(current.username, localStorage.getItem(PreferenceKeys.accessToken));
            }
        };

        this.wsClient.onclose = () => {
            console.log(`${sTag} echo-protocol client closed`);
            store.dispatch(setOnlineStatus(false));
            this.autoReconnect();
        };

        this.wsClient.onmessage = (e) => {
            const data = e.data;
            if (typeof data === 'string') {
                this.processReceivedMessage(JSON.parse(data));
            }
        };
    }

    // auto reconnect websocket
    autoReconnect() {
        setTimeout(() => {
            this.initWebsocket();
        }, 5000);
    }

    // MARK: --- Public functions ---
    // login websocket
    login(username, password) {
        if (this.wsClient.readyState === 1) {
            const data = {
                command: 'login',
                username: username,
                password: password
            };
            this.wsClient.send(JSON.stringify(data));
        }
    }

    // login websocket by token
    loginByToken(username, token) {
        if (this.wsClient.readyState === 1) {
            const data = {
                command: 'login',
                username: username,
                accessToken: token,
            };
            this.wsClient.send(JSON.stringify(data));
        }
    }

    // send message
    sendMessage(type = 'TEXT', content = '', sessionId) {
        if (this.wsClient.readyState === 1) {
            const data = {
                command: 'message',
                type: type,
                content: content,
                sessionId: sessionId,
            };
            this.wsClient.send(JSON.stringify(data));
        }
    }

    // MARK: --- Utils functions ---
    processReceivedMessage(data) {
        console.log(`${sTag} received: ${JSON.stringify(data)}`);
        if (data) {
            let { command, result } = data;
            if (command === 'on_message') {
                result = 'success';
            }
            if (result === 'success') {
                switch (command) {
                    case 'login':
                        store.dispatch(setOnlineStatus(true));
                        break;
                    case 'message':
                        {
                            const message = data.message;
                            if (message) {
                                store.dispatch(updateSessionMessage(message));
                                store.dispatch(addNewMessageSessionId(message.sessionId));
                            }
                        }
                        break;
                    case 'on_message':
                        {
                            const message = data.message;
                            if (message) {
                                store.dispatch(updateSessionMessage(message));
                                store.dispatch(addNewMessageSessionId(message.sessionId));
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }
}

// prevents modification to properties and values of an object
const wsHelperInstance = new WebsocketHelper();

// Object.freeze(wsHelperInstance);

// export 
export default wsHelperInstance;

export const injectStore = _store => {
    store = _store;
};