import { AppIcons } from "./AppResource";
const casual = require('casual-browserify');

const AppData = {
    // Sidebar menu
    sidebarMenuItems: [
        { type: 'section', text: 'Summary', icon: 'fad fa-ellipsis-h' },
        { type: 'item', text: 'Dashboard', icon: 'fad fa-analytics', path: '/dashboard' },
        { type: 'section', text: 'Manage', icon: 'fad fa-ellipsis-h' },
        { type: 'item', text: 'Account', icon: 'fad fa-users', path: '/account' },
        { type: 'item', text: 'Brand', icon: 'fad fa-watch', path: '/brand' },
        { type: 'item', text: 'Model', icon: 'fad fa-watch', path: '/model' },
        { type: 'item', text: 'Reference', icon: 'fad fa-watch', path: '/ref' },
        { type: 'item', text: 'Watch', icon: 'fad fa-watch', path: '/watch' },
        { type: 'item', text: 'Listing', icon: 'fad fa-list-alt', path: '/listing' },
        { type: 'item', text: 'Appointment', icon: 'fad fa-calendar-check', path: '/appointment' },
        // { type: 'item', text: 'Category', icon: 'fad fa-layer-group', path: '/category' },
        // { type: 'item', text: 'Transaction', icon: 'fad fa-shopping-cart', path: '/transaction' },
        { type: 'item', text: 'Article', icon: 'fad fa-newspaper', path: '/article' },
        { type: 'item', text: 'Video', icon: 'fad fa-video', path: '/video' },
        { type: 'section', text: 'Other', icon: 'fad fa-ellipsis-h' },
        { type: 'item', text: 'Message', icon: 'fad fa-comment', path: '/message' },
        { type: 'item', text: 'Notification', icon: 'fad fa-bell', path: '/notification' },
    ],

    // App languages
    languages: [
        {
            icon: AppIcons.icFlagUs,
            title: "English",
            code: "en",
        },
        {
            icon: AppIcons.icFlagVi,
            title: "Vietnamese",
            code: "vi",
        }
    ],

    // Pagination per page items
    perPageItems: [
        { value: 5 },
        { value: 10 },
        { value: 20 },
        { value: 30 },
        { value: 50 },
        { value: 100 },
    ],

    accountStates: [
        {
            value: 0,
            text: 'AccountState0'
        },
        {
            value: 1,
            text: 'AccountState1'
        },
        {
            value: 2,
            text: 'AccountState2'
        },
    ],

    accountLevels: [
        {
            value: 0,
            text: 'UserLevel0',
        },
        {
            value: 1,
            text: 'UserLevel1',
        },
        {
            value: 2,
            text: 'UserLevel2',
        },
    ],

    listingStatusItems: [
        {
            value: 'VERIFYING',
            text: 'VERIFYING'
        },
        {
            value: 'VERIFIED',
            text: 'VERIFIED'
        },
        {
            value: 'HOLDING',
            text: 'HOLDING'
        },
        {
            value: 'SOLD',
            text: 'SOLD'
        },
    ],

    appointmentStatusItems: [
        {
            value: 'UNMEET',
            text: 'UNMEET'
        },
        {
            value: 'SCHEDULED',
            text: 'SCHEDULED'
        },
        {
            value: 'CANCELLED',
            text: 'CANCELLED'
        },
        {
            value: 'MET',
            text: 'MET'
        },
        {
            value: 'SOLD',
            text: 'SOLD'
        },
        {
            value: 'NOTSOLD',
            text: 'NOTSOLD'
        },
    ],

    // MARK: --- Sample data ---
    // Sample account
    sampleAccounts: (Array(100).fill({})).map((item, index) => {
        return {
            accountId: index,
            username: casual.username,
            fullname: casual.full_name,
            email: casual.email,
            password: casual.password,
            phone: casual.phone,
            level: casual.random_element(['admin', 'moderator', 'user']),
            createdAt: casual.date('YYYY-MM-DD HH:mm:ss'),
        }
    }),


    // chart color list
    chartColos: ['#007aff', '#ff2d55', '#5856d6', '#ff9500', '#ffcc00', '#ff3b30', '#5ac8fa', '#4cd964'],
};

export default AppData;