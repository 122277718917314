import messageApi from "api/messageApi";
import Global from "general/Global";
import ToastHelper from "general/helpers/ToastHelper";
import Utils from "general/utils/Utils";

const { createSlice, createAsyncThunk, current } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// get session list
export const thunkGetSessionList = createAsyncThunk('message/allSessions', async (params, thunkAPI) => {
    // const res = await messageApi.getSessionList(params);
    const res = await messageApi.searchSession(params);
    return res.data;
});

const messageSlice = createSlice({
    name: 'message',
    initialState: {
        isGettingSessionList: false,
        allSessions: [],
        pagination: { perPage: Global.gDefaultPagination },
        selectedSessionId: 0,
        numberUnreadMessage: 0,
        newMessageSessionIds: [],
    },
    reducers: {
        setPaginationPerPage: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                }
            }
        },
        setSelectedSessionId: (state, action) => {
            state.selectedSessionId = action.payload;
        },
        updateSessionMessage: (state, action) => {
            const message = action.payload;
            const messageSessionId = message.sessionId;
            const foundSessionIndex = state.allSessions.findIndex(item => item.sessionId === messageSessionId);
            if (foundSessionIndex > -1) {
                const filteredSession = state.allSessions[foundSessionIndex];
                const foundSessionMessageIndex = filteredSession.messages.findIndex(item => item.messageId === message.messageId);
                if (foundSessionMessageIndex > -1) {
                    state.allSessions[foundSessionIndex].messages[foundSessionIndex] = message;
                } else {
                    state.allSessions[foundSessionIndex].messages.push(message);
                }
            }
            Global.gNeedToScrollBottomMessage = true;

            if (!Utils.getCurrentUrl().includes('/message')) {
                // const { type, content, account } = message;
                // ToastHelper.showInfo(content);
                state.numberUnreadMessage += 1;
            }
        },
        setNumberUnreadMessage: (state, action) => {
            state.numberUnreadMessage = action.payload;
        },
        incrementNumberUnreadMessage: (state, action) => {
            state.numberUnreadMessage += 1;
        },
        addNewMessageSessionId: (state, action) => {
            const sessionId = action.payload;
            if (state.selectedSessionId !== sessionId && !state.newMessageSessionIds.includes(sessionId)) {
                state.newMessageSessionIds.push(sessionId);
            }
        },
        removeNewMessageSessionId: (state, action) => {
            const sessionId = action.payload;
            if (current(state).newMessageSessionIds.includes(sessionId)) {
                const filteredIds = current(state).newMessageSessionIds.filter(item => item !== sessionId);
                state.newMessageSessionIds = filteredIds;
            }
        },
    },
    extraReducers: {
        // get session list
        [thunkGetSessionList.pending]: (state, action) => {
            state.isGettingSessionList = true;
        },
        [thunkGetSessionList.rejected]: (state, action) => {
            state.isGettingSessionList = false;
        },
        [thunkGetSessionList.fulfilled]: (state, action) => {
            state.isGettingSessionList = false;
            const { result, sessions, total, count, page } = action.payload;
            if (result === 'success') {
                if (sessions) {
                    if (page > 0) {
                        state.allSessions = state.allSessions.concat(sessions);
                    } else {
                        state.allSessions = sessions;
                        if (state.selectedSessionId == 0 && sessions.length > 0) {
                            state.selectedSessionId = sessions[0].sessionId;
                        }
                    }

                    state.pagination = {
                        ...state.pagination,
                        total: total,
                        count: count,
                        currentPage: page + 1,
                    };
                }
            }
        },
    },
});

const { reducer, actions } = messageSlice;
export const {
    setSelectedSessionId,
    updateSessionMessage,
    setPaginationPerPage,
    setNumberUnreadMessage,
    incrementNumberUnreadMessage,
    addNewMessageSessionId,
    removeNewMessageSessionId
} = actions;
export default reducer;