import watchApi from "api/watchApi";
import Global from "general/Global";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get watch list
export const thunkGetWatchList = createAsyncThunk('watch/list', async (params, thunkAPI) => {
    const res = await watchApi.getWatchList(params);
    return res.data;
});

// Update watch info
export const thunkUpdateWatchInfo = createAsyncThunk('watch/update', async (params, thunkAPI) => {
    const res = await watchApi.updateWatchInfo(params.watchId, params.values);
    return res.data;
});

// Create watch
export const thunkCreateWatch = createAsyncThunk('watch/create', async (params, thunkAPI) => {
    const res = await watchApi.createWatch(params);
    return res.data;
});

// Delete watch
export const thunkDeleteWatches = createAsyncThunk('watch/delete', async (watchIds, thunkAPI) => {
    const res = await watchApi.deleteWatches(watchIds);
    return res.data;
});

const watchSlice = createSlice({
    name: 'watch',
    initialState: {
        isGettingWatchList: false,
        watches: [],
        pagination: { perPage: Global.gDefaultPagination },

        isUpdatingWatch: false,
        isCreatingWatch: false,
        isDeletingWatch: false,
    },
    reducers: {
        setPaginationPerPage: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                }
            }
        },
    },
    extraReducers: {
        // Get watch list
        [thunkGetWatchList.pending]: (state, action) => {
            state.isGettingWatchList = true;
        },
        [thunkGetWatchList.rejected]: (state, action) => {
            state.isGettingWatchList = false;
        },
        [thunkGetWatchList.fulfilled]: (state, action) => {
            state.isGettingWatchList = false;
            const { result, watches, total, count, page } = action.payload;
            if (result === 'success') {
                if (watches) {
                    state.watches = watches;
                }
                state.pagination = {
                    ...state.pagination,
                    total: total,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },

        // Update watch info
        [thunkUpdateWatchInfo.pending]: (state, action) => {
            state.isUpdatingWatch = true;
        },
        [thunkUpdateWatchInfo.rejected]: (state, action) => {
            state.isUpdatingWatch = false;
        },
        [thunkUpdateWatchInfo.fulfilled]: (state, action) => {
            state.isUpdatingWatch = false;
        },

        // Create watch
        [thunkCreateWatch.pending]: (state, action) => {
            state.isCreatingWatch = true;
        },
        [thunkCreateWatch.rejected]: (state, action) => {
            state.isCreatingWatch = false;
        },
        [thunkCreateWatch.fulfilled]: (state, action) => {
            state.isCreatingWatch = false;
        },

        // Delete watch
        [thunkDeleteWatches.pending]: (state, action) => {
            state.isDeletingWatch = true;
        },
        [thunkDeleteWatches.rejected]: (state, action) => {
            state.isDeletingWatch = false;
        },
        [thunkDeleteWatches.fulfilled]: (state, action) => {
            state.isDeletingWatch = false;
        },
    },
});

const { reducer, actions } = watchSlice;
export const { setPaginationPerPage } = actions;
export default reducer;