import accountApi from "api/accountApi";
import Global from "general/Global";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get user list
export const thunkGetUserList = createAsyncThunk('account/list', async (params, thunkAPI) => {
    const res = await accountApi.getUserList(params);
    return res.data;
});

// Update user info
export const thunkUpdateUserInfo = createAsyncThunk('account/update', async (params, thunkAPI) => {
    const res = await accountApi.updateUserInfo(params.userId, params.values);
    return res.data;
});

// Create user
export const thunkCreateUser = createAsyncThunk('account/create', async (params, thunkAPI) => {
    const res = await accountApi.createUser(params);
    return res.data;
});

// Delete users
export const thunkDeleteUsers = createAsyncThunk('account/delete', async (userIds, thunkAPI) => {
    const res = await accountApi.deleteUsers(userIds);
    return res.data;
});

const accountSlice = createSlice({
    name: 'account',
    initialState: {
        isGettingUserList: false,
        users: [],
        pagination: { perPage: Global.gDefaultPagination },

        isUpdatingUser: false,
        isCreatingUser: false,
        isDeletingUser: false,
    },
    reducers: {
        setPaginationPerPage: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                }
            }
        },
    },
    extraReducers: {
        // Get user list
        [thunkGetUserList.pending]: (state, action) => {
            state.isGettingUserList = true;
        },
        [thunkGetUserList.rejected]: (state, action) => {
            state.isGettingUserList = false;
        },
        [thunkGetUserList.fulfilled]: (state, action) => {
            state.isGettingUserList = false;
            // console.log(action.payload);
            const { result, accounts, total, count, page } = action.payload;
            if (result === 'success') {
                if (accounts) {
                    state.users = accounts;
                }
                state.pagination = {
                    ...state.pagination,
                    total: total,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },

        // Update user info
        [thunkUpdateUserInfo.pending]: (state, action) => {
            state.isUpdatingUser = true;
        },
        [thunkUpdateUserInfo.rejected]: (state, action) => {
            state.isUpdatingUser = false;
        },
        [thunkUpdateUserInfo.fulfilled]: (state, action) => {
            state.isUpdatingUser = false;
        },

        // Create user
        [thunkCreateUser.pending]: (state, action) => {
            state.isCreatingUser = true;
        },
        [thunkCreateUser.rejected]: (state, action) => {
            state.isCreatingUser = false;
        },
        [thunkCreateUser.fulfilled]: (state, action) => {
            state.isCreatingUser = false;
        },

        // Delete user
        [thunkDeleteUsers.pending]: (state, action) => {
            state.isDeletingUser = true;
        },
        [thunkDeleteUsers.rejected]: (state, action) => {
            state.isDeletingUser = false;
        },
        [thunkDeleteUsers.fulfilled]: (state, action) => {
            state.isDeletingUser = false;
        },
    },
});

const { reducer, actions } = accountSlice;
export const { setPaginationPerPage } = actions;
export default reducer;