import axiosClient from "./axiosClient";

const brandApi = {
    // Get brand list
    getBrandList: (params) => {
        // console.log({ params });
        const url = '/brand';
        return axiosClient.get(url, { params });
    },

    // Get brand info by id
    getBrandInfo: (brandId) => {
        const url = `/brand/${brandId}`;
        return axiosClient.get(url);
    },

    // Update brand info
    updateBrandInfo: (brandId, params) => {
        const url = `/brand/${brandId}`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.put(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Create new brand
    createBrand: (params) => {
        const url = `/brand/create`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Delete brands
    deleteBrands: (brandIds) => {
        const url = `/brand/delete`;
        return axiosClient.post(url, {
            brandIds: brandIds
        });
    }
};

export default brandApi;