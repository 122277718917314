import axiosClient from "./axiosClient";

const modelApi = {
    // Get model list
    getModelList: (params) => {
        const url = '/model';
        return axiosClient.get(url, { params });
    },

    // Get model info by id
    getModelInfo: (modelId) => {
        const url = `/model/${modelId}`;
        return axiosClient.get(url);
    },

    // Update model info
    updateModelInfo: (modelId, params) => {
        const url = `/model/${modelId}`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.put(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Create new model
    createModel: (params) => {
        const url = `/model/create`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Delete models
    deleteModels: (modelIds) => {
        const url = `/model/delete`;
        return axiosClient.post(url, {
            modelIds: modelIds
        });
    }
};

export default modelApi;