import axiosClient from "./axiosClient";

const miscApi = {
    // upload image
    upload: (file) => {
        const url = `/misc/upload`;
        const formData = new FormData();
        formData.append('image', file);
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // get dashboard data
    getDashboardData: (params) => {
        const url = '/misc/dashboard';
        return axiosClient.get(url, { params });
    },

    // get config
    getConfig: (params) => {
        const url = '/misc/config';
        return axiosClient.get(url, { params });
    },
};

export default miscApi;