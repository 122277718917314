import authReducer from 'app/authSlice';
import appReducer from 'app/appSlice';
import accountReducer from 'features/ManageAccount/accountSlice';
import brandReducer from 'features/ManageBrand/brandSlice';
import modelReducer from 'features/ManageModel/modelSlice';
import referenceReducer from 'features/ManageReference/referenceSlice';
import watchReducer from 'features/ManageWatch/watchSlice';
import listingReducer from 'features/ManageListing/listingSlice';
import appointmentReducer from 'features/ManageAppointment/appointmentSlice';
import articleReducer from 'features/ManageArticle/articleSlice';
import videoReducer from 'features/ManageVideo/videoSlice';
import messageReducer from 'features/ManageMessage/messageSlice';
import notificationReducer from 'features/ManageNotification/notificationSlice';

const { configureStore } = require("@reduxjs/toolkit");

const rootReducer = {
    app: appReducer,
    auth: authReducer,
    account: accountReducer,
    brand: brandReducer,
    model: modelReducer,
    reference: referenceReducer,
    watch: watchReducer,
    listing: listingReducer,
    appointment: appointmentReducer,
    article: articleReducer,
    video: videoReducer,
    message: messageReducer,
    notification: notificationReducer,
};

const store = configureStore({
    reducer: rootReducer,
    devTools: false,
})

export default store