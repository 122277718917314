import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { thunkGetCurrentUserInfo, thunkSignOut } from 'app/authSlice';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import React, { Suspense, useEffect } from 'react';
import Utils from 'general/utils/Utils';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import NotFound from 'general/components/NotFound';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignInScreen from 'features/Auth/screens/SignIn';
import UserHelper from 'general/helpers/UserHelper';
import GuestRoute from 'general/components/Routes/GuestRoute';
import PrivateRoute from 'general/components/Routes/PrivateRoute';
import store from 'app/store';
import { injectStore } from 'general/helpers/WebsocketHelper';
import AdminRoute from 'general/components/Routes/AdminRoute';
import { thunkGetConfig } from 'app/appSlice';

window.jQuery = window.$ = require('jquery')
window.moment = require('moment')

// Load plugins
require("assets/plugins/ktutil")
require("assets/plugins/ktoffcanvas")
require("assets/plugins/kttoggle")
require("assets/plugins/ktmenu")
require("assets/plugins/ktcookie")
require("assets/plugins/ktcard")
require("assets/plugins/ktwizard")
require("assets/plugins/ktapp")

// Lazy load - Code splitting
const Dashboard = React.lazy(() => import('./features/Dashboard'));
const ManageAccount = React.lazy(() => import('./features/ManageAccount'));
const ManageBrand = React.lazy(() => import('./features/ManageBrand'));
const ManageModel = React.lazy(() => import('./features/ManageModel'));
const ManageRef = React.lazy(() => import('./features/ManageReference'));
const ManageWatch = React.lazy(() => import('./features/ManageWatch'));
const ManageListing = React.lazy(() => import('./features/ManageListing'));
const ManageArticle = React.lazy(() => import('./features/ManageArticle'));
const ManageVideo = React.lazy(() => import('./features/ManageVideo'));
const ManageAppointment = React.lazy(() => import('./features/ManageAppointment'));
const ManageMessage = React.lazy(() => import('./features/ManageMessage'));
const ManageNotification = React.lazy(() => import('./features/ManageNotification'));
// const ManageCategory = React.lazy(() => import('./features/ManageCategory'));
// const ManageTransaction = React.lazy(() => import('./features/ManageTransaction'));

function App() {
  // MARK: --- Params ---
  const sTag = '[App]'
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.auth.currentUser)

  // MARK: --- Functions ---
  async function getCurrentUserInfo() {
    try {
      const resGetCurrentUserInfo = unwrapResult(await dispatch(thunkGetCurrentUserInfo()))
      // Can not get info => Need sign out and back to sign-in screen
      // console.log({ resGetCurrentUserInfo });
    } catch (error) {
      console.log(`${sTag} getCurrentUserInfo error: ${error.message}`)
      // Can not get info => Need sign out and back to sign-in screen
      // signOutAndBack()
    }
  }

  // get config
  async function getConfig() {
    try {
      const resGetConfig = unwrapResult(await dispatch(thunkGetConfig()));
    } catch (error) {
      console.log(`${sTag} getConfig error: ${error.message}`)
    }
  }

  async function signOutAndBack() {
    try {
      const resSignOut = unwrapResult(await dispatch(thunkSignOut()));
    } catch (error) {
      console.log(`${sTag} sign out error: ${error.message}`);
    }
    UserHelper.signOut();
    window.location.href = '/sign-in';
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    console.log(`${sTag} viewDidLoad`);
    injectStore(store);

    return () => {
      console.log(`${sTag} viewWillDismiss`);
    }
  }, []);

  useEffect(() => {
    // Check to get current user info
    if (Utils.isObjectEmpty(currentUser) && UserHelper.checkAccessTokenValid()) {
      getCurrentUserInfo()
    }

    getConfig();
  }, [])
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Redirect exact from='/' to='/dashboard' />

            {/* Guest route */}
            <GuestRoute path='/sign-in' component={SignInScreen} />

            {/* Private route */}
            <PrivateRoute path='/dashboard' component={Dashboard} />
            <AdminRoute path='/account' component={ManageAccount} />
            <PrivateRoute path='/brand' component={ManageBrand} />
            <PrivateRoute path='/model' component={ManageModel} />
            <PrivateRoute path='/ref' component={ManageRef} />
            <PrivateRoute path='/watch' component={ManageWatch} />
            <PrivateRoute path='/listing' component={ManageListing} />
            <PrivateRoute path='/appointment' component={ManageAppointment} />
            <PrivateRoute path='/article' component={ManageArticle} />
            <PrivateRoute path='/video' component={ManageVideo} />
            <PrivateRoute path='/message' component={ManageMessage} />
            <PrivateRoute path='/notification' component={ManageNotification} />
            {/* <PrivateRoute path='/category' component={ManageCategory} /> */}
            {/* <PrivateRoute path='/transaction' component={ManageTransaction} /> */}

            {/* Not found */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </BrowserRouter>

      {/* Toast */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
