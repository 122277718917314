import { removeAxiosAccessToken } from "api/axiosClient";
import PreferenceKeys from "general/constants/PreferenceKeys";
import Global from "general/Global";
import moment from 'moment';

const UserHelper = {
    // Get display name
    getDisplayName: (user) => {
        let displayName = '';
        if (user) {
            return user.fullname ?? user.username;
        }
        return displayName;
    },

    // Get display name and phone
    getDisplayNameAndPhone: (user) => {
        let displayName = '';
        if (user) {
            displayName = user.fullname ?? user.username;

            if (user.phone) {
                displayName = displayName.concat(` - ${user.phone}`)
            }
        }
        return displayName;
    },

    // Check access token valid
    checkAccessTokenValid: () => {
        const accessToken = localStorage.getItem(PreferenceKeys.accessToken);
        const accessTokenExpired = localStorage.getItem(PreferenceKeys.accessTokenExpired);

        if (accessToken && accessTokenExpired) {
            const momentExpired = moment.utc(accessTokenExpired);
            const momentNow = moment.utc();
            return momentExpired.isAfter(momentNow);
        }

        return false;
    },

    // Check admin
    checkAdmin: () => {
        return Global.gIsAdmin;
    },

    // Sign out
    signOut: () => {
        localStorage.removeItem(PreferenceKeys.accessToken);
        localStorage.removeItem(PreferenceKeys.accessTokenExpired);
        removeAxiosAccessToken();
    },

    // Get level tag label
    getLevelTagLabel: (level) => {
        if (level === 0) {
            return 'label-primary';
        }
        if (level === 1) {
            return 'label-outline-info';
        }
        return 'label-outline-secondary';
    },

    // Get state tag label
    getStateTagLabel: (state) => {
        if (state === 0) {
            return 'label-outline-secondary';
        }
        if (state === 2) {
            return 'label-outline-danger';
        }
        return 'label-outline-primary';
    },

    // check online
    isOnline: (user) => {
        return user && user.online === 1;
    },
};

export default UserHelper;