import UserHelper from 'general/helpers/UserHelper';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PrivateRoute(props) {
    const isAuth = UserHelper.checkAccessTokenValid();

    return isAuth
        ? <Route {...props} />
        : <Redirect to='/sign-in' />
}

export default PrivateRoute;