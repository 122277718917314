const { default: axiosClient } = require("./axiosClient");

const messageApi = {
    // get session list
    getSessionList: (params) => {
        const url = '/message';
        return axiosClient.get(url, { params });
    },

    // search session
    searchSession: (params) => {
        let url = '/message/session?';
        for (const [key, value] of Object.entries(params)) {
            url = url.concat(`${key}=${value}&`);
        }
        url = url.substring(0, url.length - 1);
        return axiosClient.post(url);
    },

    // get session info
    getSessionInfo: (sessionId) => {
        const url = `/message/${sessionId}`;
        return axiosClient.get(url);
    },
};

export default messageApi;