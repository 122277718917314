import axiosClient from "./axiosClient";

const watchApi = {
    // Get watch list
    getWatchList: (params) => {
        const url = '/watch';
        return axiosClient.get(url, { params });
    },

    // Get watch info by id
    getWatchInfo: (watchId) => {
        const url = `/watch/${watchId}`;
        return axiosClient.get(url);
    },

    // Update watch info
    updateWatchInfo: (watchId, params) => {
        const url = `/watch/${watchId}`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.put(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Create new watch
    createWatch: (params) => {
        const url = `/watch/create`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            if ((key === 'images' || key === 'videos') && Array.isArray(value)) {
                value.forEach(item => {
                    formData.append(key, item);
                })
            } else {
                formData.append(key, value);
            }
        }
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Delete watches
    deleteWatches: (watchIds) => {
        const url = `/watch/delete`;
        return axiosClient.post(url, {
            watchIds: watchIds
        });
    },

    // Delete files
    deleteFiles: (watchId, filePathIds) => {
        const url = '/watch/deleteFiles';
        return axiosClient.post(url, {
            watchId: watchId,
            files: filePathIds
        });
    },

    // Add files
    addImageFiles: (watchId, imageFiles) => {
        const url = '/watch/addFiles';
        const formData = new FormData();
        formData.append("watchId", watchId);
        imageFiles.forEach(image => {
            formData.append("images", image);
        })
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Add video files
    addVideoFiles: (watchId, videoFiles) => {
        const url = '/watch/addFiles';
        const formData = new FormData();
        formData.append("watchId", watchId);
        videoFiles.forEach(video => {
            formData.append("videos", video);
        })
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // history
    getHistory: (watchId) => {
        const url = `/watch/history/${watchId}`;
        return axiosClient.post(url);
    },
};

export default watchApi;