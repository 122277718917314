import axiosClient from "./axiosClient";

const notificationApi = {
    // Get notificaltion list
    getNotificationList: (params) => {
        const url = '/notification';
        return axiosClient.get(url, { params });
    },

    // Get notification info by id
    getNotificationInfo: (notificationId) => {
        const url = `/notification/${notificationId}`;
        return axiosClient.get(url);
    },

    // Update notificaltion info
    updateNotificationInfo: (notificationId, params) => {
        const url = `/notification/${notificationId}`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.put(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Create new notification
    createNotification: (params) => {
        const url = `/notification/create`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Delete notifications
    deleteNotifications: (notificationIds) => {
        const url = `/notification/delete`;
        return axiosClient.post(url, {
            notificationIds: notificationIds
        });
    },

    // Send notification
    sendNotification: (notificationId) => {
        const url = '/notification/send';
        return axiosClient.post(url, {
            notificationId: notificationId
        });
    },
};

export default notificationApi;