import notificationApi from "api/notificationApi";
import Global from "general/Global";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get notification list
export const thunkGetNotificationList = createAsyncThunk('notification/list', async (params, thunkAPI) => {
    const res = await notificationApi.getNotificationList(params);
    return res.data;
});

// Update notification info
export const thunkUpdateNotificationInfo = createAsyncThunk('notification/update', async (params, thunkAPI) => {
    const res = await notificationApi.updateNotificationInfo(params.notificationId, params.values);
    return res.data;
});

// Create notification
export const thunkCreateNotification = createAsyncThunk('notification/create', async (params, thunkAPI) => {
    const res = await notificationApi.createNotification(params);
    return res.data;
});

// Delete notification
export const thunkDeleteNotifications = createAsyncThunk('notification/delete', async (notificationIds, thunkAPI) => {
    const res = await notificationApi.deleteNotifications(notificationIds);
    return res.data;
});

const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        isGettingNotificationList: false,
        notifications: [],
        pagination: { perPage: Global.gDefaultPagination },

        isUpdatingNotification: false,
        isCreatingNotification: false,
        isDeletingNotification: false,
    },
    reducers: {
        setPaginationPerPage: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                }
            }
        },
        updateNotificationSentStatus: (state, action) => {
            const notificationId = action.payload;
            const foundNotificationIndex = state.notifications.findIndex(item => item.notificationId === notificationId);
            if (foundNotificationIndex > -1) {
                state.notifications[foundNotificationIndex].sent = 1;
            }
        },
    },
    extraReducers: {
        // Get notification list
        [thunkGetNotificationList.pending]: (state, action) => {
            state.isGettingNotificationList = true;
        },
        [thunkGetNotificationList.rejected]: (state, action) => {
            state.isGettingNotificationList = false;
        },
        [thunkGetNotificationList.fulfilled]: (state, action) => {
            state.isGettingNotificationList = false;
            const { result, notifications, total, count, page } = action.payload;
            if (result === 'success') {
                if (notifications) {
                    state.notifications = notifications;
                }
                state.pagination = {
                    ...state.pagination,
                    total: total,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },

        // Update notification info
        [thunkUpdateNotificationInfo.pending]: (state, action) => {
            state.isUpdatingNotification = true;
        },
        [thunkUpdateNotificationInfo.rejected]: (state, action) => {
            state.isUpdatingNotification = false;
        },
        [thunkUpdateNotificationInfo.fulfilled]: (state, action) => {
            state.isUpdatingNotification = false;
        },

        // Create notification
        [thunkCreateNotification.pending]: (state, action) => {
            state.isCreatingNotification = true;
        },
        [thunkCreateNotification.rejected]: (state, action) => {
            state.isCreatingNotification = false;
        },
        [thunkCreateNotification.fulfilled]: (state, action) => {
            state.isCreatingNotification = false;
        },

        // Delete notification
        [thunkDeleteNotifications.pending]: (state, action) => {
            state.isDeletingNotification = true;
        },
        [thunkDeleteNotifications.rejected]: (state, action) => {
            state.isDeletingNotification = false;
        },
        [thunkDeleteNotifications.fulfilled]: (state, action) => {
            state.isDeletingNotification = false;
        },
    },
});

const { reducer, actions } = notificationSlice;
export const { setPaginationPerPage, updateNotificationSentStatus } = actions;
export default reducer;