import articleApi from "api/articleApi";
import Global from "general/Global";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get article list
export const thunkGetArticleList = createAsyncThunk('article/list', async (params, thunkAPI) => {
    const res = await articleApi.getArticleList(params);
    return res.data;
});

// Update article info
export const thunkUpdateArticleInfo = createAsyncThunk('article/update', async (params, thunkAPI) => {
    const res = await articleApi.updateArticleInfo(params.articleId, params.values);
    return res.data;
});

// Create article
export const thunkCreateArticle = createAsyncThunk('article/create', async (params, thunkAPI) => {
    const res = await articleApi.createArticle(params);
    return res.data;
});

// Delete article
export const thunkDeleteArticles = createAsyncThunk('article/delete', async (articleIds, thunkAPI) => {
    const res = await articleApi.deleteArticles(articleIds);
    return res.data;
});

const articleSlice = createSlice({
    name: 'article',
    initialState: {
        isGettingArticleList: false,
        articles: [],
        pagination: { perPage: Global.gDefaultPagination },

        isUpdatingArticle: false,
        isCreatingArticle: false,
        isDeletingArticle: false,
    },
    reducers: {
        setPaginationPerPage: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                }
            }
        },
    },
    extraReducers: {
        // Get article list
        [thunkGetArticleList.pending]: (state, action) => {
            state.isGettingArticleList = true;
        },
        [thunkGetArticleList.rejected]: (state, action) => {
            state.isGettingArticleList = false;
        },
        [thunkGetArticleList.fulfilled]: (state, action) => {
            state.isGettingArticleList = false;
            const { result, articles, total, count, page } = action.payload;
            if (result === 'success') {
                if (articles) {
                    state.articles = articles;
                }
                state.pagination = {
                    ...state.pagination,
                    total: total,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },

        // Update article info
        [thunkUpdateArticleInfo.pending]: (state, action) => {
            state.isUpdatingArticle = true;
        },
        [thunkUpdateArticleInfo.rejected]: (state, action) => {
            state.isUpdatingArticle = false;
        },
        [thunkUpdateArticleInfo.fulfilled]: (state, action) => {
            state.isUpdatingArticle = false;
        },

        // Create article
        [thunkCreateArticle.pending]: (state, action) => {
            state.isCreatingArticle = true;
        },
        [thunkCreateArticle.rejected]: (state, action) => {
            state.isCreatingArticle = false;
        },
        [thunkCreateArticle.fulfilled]: (state, action) => {
            state.isCreatingArticle = false;
        },

        // Delete article
        [thunkDeleteArticles.pending]: (state, action) => {
            state.isDeletingArticle = true;
        },
        [thunkDeleteArticles.rejected]: (state, action) => {
            state.isDeletingArticle = false;
        },
        [thunkDeleteArticles.fulfilled]: (state, action) => {
            state.isDeletingArticle = false;
        },
    },
});

const { reducer, actions } = articleSlice;
export const { setPaginationPerPage } = actions;
export default reducer;