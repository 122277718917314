import brandApi from "api/brandApi";
import Global from "general/Global";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get brand list
export const thunkGetBrandList = createAsyncThunk('brand/list', async (params, thunkAPI) => {
    const res = await brandApi.getBrandList(params);
    return res.data;
});

// Update brand info
export const thunkUpdateBrandInfo = createAsyncThunk('brand/update', async (params, thunkAPI) => {
    const res = await brandApi.updateBrandInfo(params.brandId, params.values);
    return res.data;
});

// Create brand
export const thunkCreateBrand = createAsyncThunk('brand/create', async (params, thunkAPI) => {
    const res = await brandApi.createBrand(params);
    return res.data;
});

// Delete brand
export const thunkDeleteBrands = createAsyncThunk('brand/delete', async (brandIds, thunkAPI) => {
    const res = await brandApi.deleteBrands(brandIds);
    return res.data;
});

const brandSlice = createSlice({
    name: 'brand',
    initialState: {
        isGettingBrandList: false,
        brands: [],
        pagination: { perPage: Global.gDefaultPagination },

        isUpdatingBrand: false,
        isCreatingBrand: false,
        isDeletingBrand: false,
    },
    reducers: {
        setPaginationPerPage: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                }
            }
        },
    },
    extraReducers: {
        // Get brand list
        [thunkGetBrandList.pending]: (state, action) => {
            state.isGettingBrandList = true;
        },
        [thunkGetBrandList.rejected]: (state, action) => {
            state.isGettingBrandList = false;
        },
        [thunkGetBrandList.fulfilled]: (state, action) => {
            state.isGettingBrandList = false;
            const { result, brands, total, count, page } = action.payload;
            if (result === 'success') {
                if (brands) {
                    state.brands = brands;
                }
                state.pagination = {
                    ...state.pagination,
                    total: total,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },

        // Update brand info
        [thunkUpdateBrandInfo.pending]: (state, action) => {
            state.isUpdatingBrand = true;
        },
        [thunkUpdateBrandInfo.rejected]: (state, action) => {
            state.isUpdatingBrand = false;
        },
        [thunkUpdateBrandInfo.fulfilled]: (state, action) => {
            state.isUpdatingBrand = false;
        },

        // Create brand
        [thunkCreateBrand.pending]: (state, action) => {
            state.isCreatingBrand = true;
        },
        [thunkCreateBrand.rejected]: (state, action) => {
            state.isCreatingBrand = false;
        },
        [thunkCreateBrand.fulfilled]: (state, action) => {
            state.isCreatingBrand = false;
        },

        // Delete brand
        [thunkDeleteBrands.pending]: (state, action) => {
            state.isDeletingBrand = true;
        },
        [thunkDeleteBrands.rejected]: (state, action) => {
            state.isDeletingBrand = false;
        },
        [thunkDeleteBrands.fulfilled]: (state, action) => {
            state.isDeletingBrand = false;
        },
    },
});

const { reducer, actions } = brandSlice;
export const { setPaginationPerPage } = actions;
export default reducer;