import axiosClient from "./axiosClient";

const articleApi = {
    // Get article list
    getArticleList: (params) => {
        const url = '/article';
        return axiosClient.get(url, { params });
    },

    // Get article info by id
    getArticleInfo: (articleId) => {
        const url = `/article/${articleId}`;
        return axiosClient.get(url);
    },

    // Update article info
    updateArticleInfo: (articleId, params) => {
        const url = `/article/${articleId}`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.put(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Create new article
    createArticle: (params) => {
        const url = `/article/create`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Delete articles
    deleteArticles: (articleIds) => {
        const url = `/article/delete`;
        return axiosClient.post(url, {
            articleIds: articleIds
        });
    }
};

export default articleApi;