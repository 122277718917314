import appointmentApi from "api/appointmentApi";
import Global from "general/Global";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get appointment list
export const thunkGetAppointmentList = createAsyncThunk('appointment/list', async (params, thunkAPI) => {
    const res = await appointmentApi.getAppointmentList(params);
    return res.data;
});

// Update appointment info
export const thunkUpdateAppointmentInfo = createAsyncThunk('appointment/update', async (params, thunkAPI) => {
    const res = await appointmentApi.updateAppointmentInfo(params.appointmentId, params.values);
    return res.data;
});

// Create appointment
export const thunkCreateAppointment = createAsyncThunk('appointment/create', async (params, thunkAPI) => {
    const res = await appointmentApi.createAppointment(params);
    return res.data;
});

// Delete appointments
export const thunkDeleteAppointments = createAsyncThunk('appointment/delete', async (appointmentIds, thunkAPI) => {
    const res = await appointmentApi.deleteAppointments(appointmentIds);
    return res.data;
});

const appointmentSlice = createSlice({
    name: 'appointment',
    initialState: {
        isGettingAppointmentList: false,
        appointments: [],
        pagination: { perPage: Global.gDefaultPagination },

        isUpdatingAppointment: false,
        isCreatingAppointment: false,
        isDeletingAppointment: false,
    },
    reducers: {
        setPaginationPerPage: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                }
            }
        },
    },
    extraReducers: {
        // Get appointment list
        [thunkGetAppointmentList.pending]: (state, action) => {
            state.isGettingAppointmentList = true;
        },
        [thunkGetAppointmentList.rejected]: (state, action) => {
            state.isGettingAppointmentList = false;
        },
        [thunkGetAppointmentList.fulfilled]: (state, action) => {
            state.isGettingAppointmentList = false;
            const { result, appointments, total, count, page } = action.payload;
            if (result === 'success') {
                if (appointments) {
                    state.appointments = appointments;
                }
                state.pagination = {
                    ...state.pagination,
                    total: total,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },

        // Update appointment info
        [thunkUpdateAppointmentInfo.pending]: (state, action) => {
            state.isUpdatingAppointment = true;
        },
        [thunkUpdateAppointmentInfo.rejected]: (state, action) => {
            state.isUpdatingAppointment = false;
        },
        [thunkUpdateAppointmentInfo.fulfilled]: (state, action) => {
            state.isUpdatingAppointment = false;
        },

        // Create appointment
        [thunkCreateAppointment.pending]: (state, action) => {
            state.isCreatingAppointment = true;
        },
        [thunkCreateAppointment.rejected]: (state, action) => {
            state.isCreatingAppointment = false;
        },
        [thunkCreateAppointment.fulfilled]: (state, action) => {
            state.isCreatingAppointment = false;
        },

        // Delete appointment
        [thunkDeleteAppointments.pending]: (state, action) => {
            state.isDeletingAppointment = true;
        },
        [thunkDeleteAppointments.rejected]: (state, action) => {
            state.isDeletingAppointment = false;
        },
        [thunkDeleteAppointments.fulfilled]: (state, action) => {
            state.isDeletingAppointment = false;
        },
    },
});

const { reducer, actions } = appointmentSlice;
export const { setPaginationPerPage } = actions;
export default reducer;