import axiosClient from "./axiosClient";

const appointmentApi = {
    // Get appointment list
    getAppointmentList: (params) => {
        const url = '/appointment';
        return axiosClient.get(url, { params });
    },

    // Get appointment info by id
    getAppointmentInfo: (appointmentId) => {
        const url = `/appointment/${appointmentId}`;
        return axiosClient.get(url);
    },

    // Update appointment info
    updateAppointmentInfo: (appointmentId, params) => {
        const url = `/appointment/${appointmentId}`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.put(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Create new appointment
    createAppointment: (params) => {
        const url = `/appointment/create`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Delete appointments
    deleteAppointments: (appointmentIds) => {
        const url = `/appointment/delete`;
        return axiosClient.post(url, {
            appointmentIds: appointmentIds
        });
    },
};

export default appointmentApi;