import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './style.scss';
import 'assets/styles/login-3.css';
import { AppIcons, AppImages } from 'general/constants/AppResource';
import SignInForm from 'features/Auth/components/SignInForm';
import ToastHelper from 'general/helpers/ToastHelper';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { thunkSignIn } from 'app/authSlice';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import AppData from 'general/constants/AppData';
import UserHelper from 'general/helpers/UserHelper';
import Utils from 'general/utils/Utils';

SignInScreen.propTypes = {

};

function SignInScreen(props) {
    // MARK: --- Params ---
    const sTag = '[SignInScreen]';
    const history = useHistory();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    let currentAppLanguage = i18n.language;
    if (!currentAppLanguage) {
        currentAppLanguage = 'en';
    }
    let currentAppLanguageItem = AppData.languages.find(item => currentAppLanguage.indexOf(item.code) !== -1);
    if (!currentAppLanguageItem) {
        currentAppLanguageItem = AppData.languages[0];
    }
    const [languageMenuVisible, setLanguageMenuVisible] = useState(false);
    const { isSigningIn } = useSelector(state => state.auth);

    // MARK: --- Functions ---
    async function handleSubmit(values) {
        if (isSigningIn) {
            return;
        }
        const sha256Password = Utils.sha256(values.password);
        const params = { ...values, password: sha256Password };
        try {
            const res = unwrapResult(await dispatch(thunkSignIn(params)));
            const { result, account } = res;
            if (result === 'success' && account) {
                if (account.level > 1) {
                    ToastHelper.showError(t('PermissionDenied'));
                    UserHelper.signOut();
                } else {
                    ToastHelper.showSuccess(`${t('Hello')}, ${UserHelper.getDisplayName(account) ?? 'Admin'}`);
                    history.replace('/');
                }
            }
        } catch (error) { }
    }

    function handleSignInGoogle() {
        console.log(`${sTag} on sign in Google`);
    }

    function handleChangedLanguage(lang) {
        setLanguageMenuVisible(false);
        i18n.changeLanguage(lang);
    }

    function toggleLanguageMenu() {
        setLanguageMenuVisible(!languageMenuVisible);
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} viewDidLoad`);

        return () => {
            console.log(`${sTag} viewWillDismiss`);
        };
    }, []);

    return (
        <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
            {/* begin::Sign In */}
            <div className="login login-3 wizard d-flex flex-column flex-lg-row flex-column-fluid">
                {/* begin::Aside */}
                <div className="login-aside d-flex flex-column flex-row-auto SignIn_Aside position-relative max-w-500px">
                    {/* <div className='w-100 h-100 SignIn_AsideBG d-flex align-items-end justify-content-center'>
                        <div className='py-20'>
                            <img
                                className=''
                                alt='logo'
                                src={AppIcons.icLogoDNK}
                            />
                            <div className='text-white font-size-h2 font-weight-boldest'>{t('DnKText')}</div>
                        </div>
                    </div> */}
                </div>
                {/* end::Aside */}

                {/* begin::Content */}
                <div className="login-content flex-row-fluid d-flex flex-column p-10">
                    {/* begin::Top */}
                    <div className="text-right d-flex justify-content-center align-items-center">
                        <div className="top-signin text-right d-flex justify-content-end pb-lg-0">
                            <span className="font-weight-bold text-muted font-size-h5">{`${t('ChangeLanguage')}`}</span>
                            {/* <a href="#" className="font-weight-bold text-primary font-size-h5 ml-2" id="kt_login_signup">{t('Help')}</a> */}
                        </div>
                        {/* Language */}
                        <div className="">
                            {/* Languages */}
                            <Dropdown
                                isOpen={languageMenuVisible}
                                toggle={toggleLanguageMenu}
                                className="ml-2"
                            >
                                <DropdownToggle caret={false} className="border-0 p-0 bg-transparent">
                                    <div className="topbar-item" data-toggle="dropdown" data-offset="10px,0px" aria-expanded={`${languageMenuVisible ? 'true' : 'false'}`}>
                                        <a href='#' className="btn btn-icon btn-bg-white btn-hover-primary btn-icon-primary btn-circle btn-dropdown" onClick={toggleLanguageMenu}>
                                            <img className="h-20px w-20px rounded-circle" src={currentAppLanguageItem.icon} alt="" />
                                        </a>
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className='py-4'>
                                    {
                                        AppData.languages.map((item, index) => {
                                            return (
                                                <DropdownItem
                                                    key={index}
                                                    active={item.code === currentAppLanguageItem.code}
                                                    onClick={() => {
                                                        handleChangedLanguage(item.code);
                                                    }}
                                                >
                                                    <span className="symbol symbol-20 mr-3">
                                                        <img src={item.icon} alt="" />
                                                    </span>
                                                    <span className="navi-text">{t(item.title)}</span>
                                                </DropdownItem>
                                            );
                                        })
                                    }
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                    {/* end::Top */}

                    {/* begin::Wrapper */}
                    <div className="d-flex flex-row-fluid flex-center">
                        {/* begin::Sign In */}
                        <div className="login-form">
                            {/* begin: Form */}
                            <SignInForm
                                onSubmit={handleSubmit}
                                onSignInGoogle={handleSignInGoogle}
                            />
                            {/* end: Form */}
                        </div>
                        {/* end::Sign In */}
                    </div>
                    {/* end::Wrapper */}
                </div>
                {/* end::Content */}
            </div>
            {/* end::Sign In */}
        </div>
    );
}

export default SignInScreen;