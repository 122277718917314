import referenceApi from "api/referenceApi";
import Global from "general/Global";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get reference list
export const thunkGetReferenceList = createAsyncThunk('reference/list', async (params, thunkAPI) => {
    const res = await referenceApi.getReferenceList(params);
    return res.data;
});

// Update reference info
export const thunkUpdateReferenceInfo = createAsyncThunk('reference/update', async (params, thunkAPI) => {
    const res = await referenceApi.updateReferenceInfo(params.referenceId, params.values);
    return res.data;
});

// Create reference
export const thunkCreateReference = createAsyncThunk('reference/create', async (params, thunkAPI) => {
    const res = await referenceApi.createReference(params);
    return res.data;
});

// Delete reference
export const thunkDeleteReferences = createAsyncThunk('reference/delete', async (referenceIds, thunkAPI) => {
    const res = await referenceApi.deleteReferences(referenceIds);
    return res.data;
});

const referenceSlice = createSlice({
    name: 'reference',
    initialState: {
        isGettingReferenceList: false,
        references: [],
        pagination: { perPage: Global.gDefaultPagination },

        isUpdatingReference: false,
        isCreatingReference: false,
        isDeletingReference: false,
    },
    reducers: {
        setPaginationPerPage: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                }
            }
        },
    },
    extraReducers: {
        // Get reference list
        [thunkGetReferenceList.pending]: (state, action) => {
            state.isGettingReferenceList = true;
        },
        [thunkGetReferenceList.rejected]: (state, action) => {
            state.isGettingReferenceList = false;
        },
        [thunkGetReferenceList.fulfilled]: (state, action) => {
            state.isGettingReferenceList = false;
            const { result, references, total, count, page } = action.payload;
            if (result === 'success') {
                if (references) {
                    state.references = references;
                }
                state.pagination = {
                    ...state.pagination,
                    total: total,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },

        // Update reference info
        [thunkUpdateReferenceInfo.pending]: (state, action) => {
            state.isUpdatingReference = true;
        },
        [thunkUpdateReferenceInfo.rejected]: (state, action) => {
            state.isUpdatingReference = false;
        },
        [thunkUpdateReferenceInfo.fulfilled]: (state, action) => {
            state.isUpdatingReference = false;
        },

        // Create reference
        [thunkCreateReference.pending]: (state, action) => {
            state.isCreatingReference = true;
        },
        [thunkCreateReference.rejected]: (state, action) => {
            state.isCreatingReference = false;
        },
        [thunkCreateReference.fulfilled]: (state, action) => {
            state.isCreatingReference = false;
        },

        // Delete reference
        [thunkDeleteReferences.pending]: (state, action) => {
            state.isDeletingReference = true;
        },
        [thunkDeleteReferences.rejected]: (state, action) => {
            state.isDeletingReference = false;
        },
        [thunkDeleteReferences.fulfilled]: (state, action) => {
            state.isDeletingReference = false;
        },
    },
});

const { reducer, actions } = referenceSlice;
export const { setPaginationPerPage } = actions;
export default reducer;