const Global = {
    gActivePath: '',
    gIsAdmin: false,

    gNeedToRefreshUserList: false,
    gNeedToRefreshBrandList: false,
    gNeedToRefreshModelList: false,
    gNeedToRefreshRefList: false,
    gNeedToRefreshWatchList: false,
    gNeedToRefreshListingList: false,
    gNeedToRefreshAppointmentList: false,
    gNeedToRefreshArticleList: false,
    gNeedToRefreshVideoList: false,
    gNeedToRefreshSessionList: false,
    gNeedToRefreshNotificationList: false,

    gDefaultPagination: 30,
    gNeedToScrollBottomMessage: true,

    // Filters
    gFiltersUserList: {
        page: 0,
        limit: 30,
        q: '',
    },
    gFiltersBrandList: {
        page: 0,
        limit: 30,
        q: '',
    },
    gFiltersModelList: {
        page: 0,
        limit: 30,
        q: '',
    },
    gFiltersRefList: {
        page: 0,
        limit: 30,
        q: '',
    },
    gFiltersWatchList: {
        page: 0,
        limit: 30,
        q: '',
    },
    gFiltersListingList: {
        page: 0,
        limit: 30,
        q: '',
    },
    gFiltersAppointmentList: {
        page: 0,
        limit: 30,
        q: '',
    },
    gFiltersArticleList: {
        page: 0,
        limit: 30,
        q: '',
    },
    gFiltersVideoList: {
        page: 0,
        limit: 30,
        q: '',
    },
    gFiltersSessionList: {
        page: 0,
        limit: 30,
        q: '',
    },
    gFiltersNotificationList: {
        page: 0,
        limit: 30,
        q: '',
    },
};

export default Global;