import listingApi from "api/listingApi";
import Global from "general/Global";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get listing list
export const thunkGetListingList = createAsyncThunk('listing/list', async (params, thunkAPI) => {
    const res = await listingApi.getListingList(params);
    return res.data;
});

// Update listing info
export const thunkUpdateListingInfo = createAsyncThunk('listing/update', async (params, thunkAPI) => {
    const res = await listingApi.updateListingInfo(params.listingId, params.values);
    return res.data;
});

// Create listing
export const thunkCreateListing = createAsyncThunk('listing/create', async (params, thunkAPI) => {
    const res = await listingApi.createListing(params);
    return res.data;
});

// Delete listing
export const thunkDeleteListings = createAsyncThunk('listing/delete', async (listingIds, thunkAPI) => {
    const res = await listingApi.deleteListings(listingIds);
    return res.data;
});



const listingSlice = createSlice({
    name: 'listing',
    initialState: {
        isGettingListingList: false,
        listings: [],
        pagination: { perPage: Global.gDefaultPagination },

        isUpdatingListing: false,
        isCreatingListing: false,
        isDeletingListing: false,
    },
    reducers: {
        setPaginationPerPage: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                }
            }
        },
    },
    extraReducers: {
        // Get listing list
        [thunkGetListingList.pending]: (state, action) => {
            state.isGettingListingList = true;
        },
        [thunkGetListingList.rejected]: (state, action) => {
            state.isGettingListingList = false;
        },
        [thunkGetListingList.fulfilled]: (state, action) => {
            state.isGettingListingList = false;
            const { result, listings, total, count, page } = action.payload;
            if (result === 'success') {
                if (listings) {
                    state.listings = listings;
                }
                state.pagination = {
                    ...state.pagination,
                    total: total,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },

        // Update listing info
        [thunkUpdateListingInfo.pending]: (state, action) => {
            state.isUpdatingListing = true;
        },
        [thunkUpdateListingInfo.rejected]: (state, action) => {
            state.isUpdatingListing = false;
        },
        [thunkUpdateListingInfo.fulfilled]: (state, action) => {
            state.isUpdatingListing = false;
        },

        // Create listing
        [thunkCreateListing.pending]: (state, action) => {
            state.isCreatingListing = true;
        },
        [thunkCreateListing.rejected]: (state, action) => {
            state.isCreatingListing = false;
        },
        [thunkCreateListing.fulfilled]: (state, action) => {
            state.isCreatingListing = false;
        },

        // Delete listing
        [thunkDeleteListings.pending]: (state, action) => {
            state.isDeletingListing = true;
        },
        [thunkDeleteListings.rejected]: (state, action) => {
            state.isDeletingListing = false;
        },
        [thunkDeleteListings.fulfilled]: (state, action) => {
            state.isDeletingListing = false;
        },
    },
});

const { reducer, actions } = listingSlice;
export const { setPaginationPerPage } = actions;
export default reducer;