import modelApi from "api/modelApi";
import Global from "general/Global";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get model list
export const thunkGetModelList = createAsyncThunk('model/list', async (params, thunkAPI) => {
    const res = await modelApi.getModelList(params);
    return res.data;
});

// Update model info
export const thunkUpdateModelInfo = createAsyncThunk('model/update', async (params, thunkAPI) => {
    const res = await modelApi.updateModelInfo(params.modelId, params.values);
    return res.data;
});

// Create model
export const thunkCreateModel = createAsyncThunk('model/create', async (params, thunkAPI) => {
    const res = await modelApi.createModel(params);
    return res.data;
});

// Delete model
export const thunkDeleteModels = createAsyncThunk('model/delete', async (modelIds, thunkAPI) => {
    const res = await modelApi.deleteModels(modelIds);
    return res.data;
});

const modelSlice = createSlice({
    name: 'model',
    initialState: {
        isGettingModelList: false,
        models: [],
        pagination: { perPage: Global.gDefaultPagination },

        isUpdatingModel: false,
        isCreatingModel: false,
        isDeletingModel: false,
    },
    reducers: {
        setPaginationPerPage: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                }
            }
        },
    },
    extraReducers: {
        // Get model list
        [thunkGetModelList.pending]: (state, action) => {
            state.isGettingModelList = true;
        },
        [thunkGetModelList.rejected]: (state, action) => {
            state.isGettingModelList = false;
        },
        [thunkGetModelList.fulfilled]: (state, action) => {
            state.isGettingModelList = false;
            const { result, models, total, count, page } = action.payload;
            if (result === 'success') {
                if (models) {
                    state.models = models;
                }
                state.pagination = {
                    ...state.pagination,
                    total: total,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },

        // Update model info
        [thunkUpdateModelInfo.pending]: (state, action) => {
            state.isUpdatingModel = true;
        },
        [thunkUpdateModelInfo.rejected]: (state, action) => {
            state.isUpdatingModel = false;
        },
        [thunkUpdateModelInfo.fulfilled]: (state, action) => {
            state.isUpdatingModel = false;
        },

        // Create model
        [thunkCreateModel.pending]: (state, action) => {
            state.isCreatingModel = true;
        },
        [thunkCreateModel.rejected]: (state, action) => {
            state.isCreatingModel = false;
        },
        [thunkCreateModel.fulfilled]: (state, action) => {
            state.isCreatingModel = false;
        },

        // Delete model
        [thunkDeleteModels.pending]: (state, action) => {
            state.isDeletingModel = true;
        },
        [thunkDeleteModels.rejected]: (state, action) => {
            state.isDeletingModel = false;
        },
        [thunkDeleteModels.fulfilled]: (state, action) => {
            state.isDeletingModel = false;
        },
    },
});

const { reducer, actions } = modelSlice;
export const { setPaginationPerPage } = actions;
export default reducer;