import { toast } from 'react-toastify';

const ToastHelper = {
    showSuccess: (message) => {
        toast.success(message);
    },

    showError: (message) => {
        toast.error(message);
    },

    showInfo: (message) => {
        toast.info(message);
    },

    showDefault: (message) => {
        toast(message);
    },
};

export default ToastHelper;